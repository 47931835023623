.notsupported__container {
  width: 100%;
  height: 100vh;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.notsupported__navbar {
  /* padding: 0 50px; */
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notsupported__navbar img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.notsupported__navbar p {
  font-size: 20px;
  font-weight: bold;
}

.notsupported__main {
  height: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */
}
.notsupported__main__info {
  font-size: 25px;
  font-weight: bold;
  text-transform: capitalize;
}

.notsupported__main__todo {
  font-size: 22px;
  text-transform: capitalize;
}

.notsupported__main a {
  margin-top: 20px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  padding: 15px;
  text-transform: capitalize;
  border-radius: 10px;
  background-color: #4087f2;
  color: #fff !important;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .notsupported__main {
    width: 100%;
    text-align: center;
  }

  .notsupported__navbar img{
    width: 80px;
    height: 80px;
  }

  .notsupported__main__info {
    font-size: 18px;
  }

  .notsupported__main__todo {
    width: 90%;
    font-size: 16px;
    text-transform: capitalize;
  }
  .notsupported__main a {
    font-size: 16px;
  }
}
