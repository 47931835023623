@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #d5d5d5;
  font-family: "Rubik", sans-serif;
}

img {
  max-width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

.main_wrapper {
  width: 100%;
  height: 150%;
  padding: 20px 0px;
  background-color: #d5d5d5;
}

.leftbar {
  max-width: 300px;
  width: 100%;
  height: 100vh;
  background-color: #ebebeb;
}

.navtab {
  width: 100%;
  padding: 15px;
}

.video_chat .nav-link:hover {
  background-color: rgba(0, 123, 255, 0.137);
  color: black;
}

.logo {
  padding: 15px;
  margin-bottom: 20px;
}

.logo h1 {
  font-size: 26px;
  color: #ffffff;
  padding-top: 20px;
}

.rightbar {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.videostn {
  padding: 120px 20px;
}

.videopart {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0px 0px 8px #dbdbdb;*/
}

.usericon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #4dc1ed;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
}

.vdrighticn {
  list-style: none;
  display: inline-block;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 10px;
  padding-bottom: 10px;
  flex-direction: row;
}

.vdrighticn a {
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  display: block;
  background-color: #666;
  color: #fff;
  text-align: center;
  font-size: 15px;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.vdrighticn a:hover {
  background-color: #555;
}

.vdrighticn i {
  font-size: 18px;
}

.vdbotm {
  width: 95%;
  position: absolute;
  bottom: 2px;
}

.loginbox {
  background-color: #fff;
  border-radius: 4px;
}

.loginbox input {
  height: 45px;
}

.loginbox button {
  height: 45px;
}

.permision {
  background: rgb(231 203 203);
  padding: 10px 15px;
}

.permision p {
  font-size: 15px;
  margin-bottom: 0px;
}

.persn {
  width: 30%;
}

.persn i {
  float: left;
  width: 40px;
  height: 60px;
  color: #ed6464;
  font-size: 24px;
  line-height: 50px;
}

.prcont {
  width: 70%;
}

.advanced {
  display: block;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.advanced:hover {
  border: 1px solid #2ab0e3;
}

.advicon {
  padding-top: 20px;
  font-size: 18px;
}

.adcnts {
  padding-left: 15px;
}

.adcnts h5 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 16px;
}

.advand_show {
  margin-top: 15px;
}

.advand_show input {
  height: auto;
}

.advand_show h5 {
  font-size: 16px;
  margin-bottom: 30px;
}

.vdrighticn img {
  width: 25px;
}

.advand_show a {
  text-decoration: none;
  color: #333;
}

.ckshow input {
  height: 35px;
}

.adbackbt {
  margin-bottom: 30px;
}

.backfrm {
  color: #333;
  font-size: 15px;
  cursor: pointer;
}

.padd1 {
  padding-left: 5px;
  padding-right: 5px;
}

.video {
  width: 100%;
  height: 350px;
  background-color: #fff;
  position: relative;
}

.mainboxes h3 {
  text-align: center;
  font-size: 24px;
  padding-top: 15px;
}

.viseicon {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 50px;
  height: 40px;
  line-height: 40px;
  background-color: #666;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.viseicon:hover {
  background-color: #414141;
}

.mutemike .btn-light,
.volspekr .btn-light {
  background-color: #efefef !important;
  border: none;
}

.vdclist {
  margin: 0px;
  padding: 0px;
}

.vdclist li {
  list-style: none;
  display: inline-block;
  margin-left: 5px;
}

.vdclist li a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  display: block;
  text-align: center;
  transition: all 0.9s ease-in-out;
}

.vdclist li a:hover {
  background-color: #318ff3;
}

.vdclist li a i {
  transition: all 0.9s ease-in-out;
}

.vdclist li a:hover i {
  transform: scale(1.2);
}

.container {
  min-height: 92%;
  height: 90%;
  max-width: 1180px;
}

/*----- Video Calling ------*/

.vcall {
  height: 90vh;
}

.btmcall {
  width: 100%;
  z-index: 1000;
  position: absolute;
  left: 0px;
  bottom: 20px;
}

.btmcall ul li {
  padding: 5px;
  margin: 0px 5px;
  list-style: none;
  display: inline-block;
  cursor: pointer;
}

.btmcall ul li a {
  text-decoration: none;
  color: #fff;
  background-color: rgb(89 96 98 / 70%);
  width: 50px;
  height: 50px;
  display: block;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  overflow: hidden;
}

.btmcall ul li a:hover {
  opacity: 0.8;
}

.btmcall ul li a i {
  transition: all 0.5s ease-in-out;
}

.btmcall ul li a:hover i {
  transform: scale(1.2);
}

.btmcallredcolor {
  background-color: #fc5d5b;
}

.rgvideo {
  padding-right: 10px;
  overflow-y: scroll;
  height: 400px;
}

.galleryButton {
  position: absolute;
  bottom: 0px;
  color: white;
  background-color: rgba(14, 14, 14, 0.63);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 1000;
  padding: auto;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  font-weight: 800;
}

​span.gallerybottomname {
  position: absolute;
  font-size: 14px;
  bottom: 5px;
  left: 5px;
  border-radius: 2px;
  padding: 5px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.438);
  color: white;
}

.bars {
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  right: 5px;
}

.vh_options {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background: white;
  bottom: 0px;
  transition: all 0.2s ease;
  animation-delay: 0.2s;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.showOptions {
  width: 200px;
  opacity: 1;
}

.hideOptions {
  opacity: 0;
  width: 0px;
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: 15px;
}

.non-pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: 15px;
}

.non-pulsating-circle::before {
  content: "";
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 45px;
  background-color: transparent;
}

.non-pulsating-circle::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(255, 144, 144, 0.3);
}

.pulsating-circle::before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #ffa0a0e3;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgb(214, 23, 23);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.not-col {
  width: 0px;
  display: flex;
  flex: 0;
  height: 0px;
  overflow: hidden;
}

.leftpanel {
  transition: 0.2s all ease;
}

.rightpanel {
  transition: 0.2s all ease;
}

.chatToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  top: 0px;
  right: -10px;
  z-index: 10;
  position: absolute;
  transition: all ease 0.3s;
}

.bluebg {
  background-color: rgb(0, 122, 254);
  color: white;
}

.whitebg {
  transform: rotate(360deg);
  background-color: white;
  color: rgb(0, 122, 254);
}

.cross {
  height: 30px;
  width: 30px;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: rgb(0, 122, 254);
  border-radius: 50%;
  position: absolute;
  text-align: center;
  line-height: 30px;
  color: white;
  font-size: 10px;
  font-weight: 800;
}

.showCross {
  top: -10px;
  opacity: 1;
  right: -10px;
}

.hideCross {
  top: 10px;
  opacity: 0;
  right: -10px;
}

.left {
  position: absolute;
  top: 0;
  left: 0;
}

.right {
  position: absolute;
  top: 0;
  right: 0;
}

.arrow svg {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem !important;
  transform: translate(-50%, -50%);
}

.arrow {
  padding: 10px;
  cursor: pointer;
  background-color: rgba(112, 128, 144, 0.192);
  border: 1px solid white;
  color: black;
  transition: all 0.2 ease;
  height: 30px;
  z-index: 10000000;
  border-radius: 3px;
  width: 40px;
}

.arrow:hover {
  background-color: rgba(46, 46, 46, 0.856);
  color: white;
}

.bar {
  position: relative;
  background: rgb(106, 255, 198);
  height: 1px;
  margin: 0px 1px;
  border-radius: 4px;
  width: 3px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 18px;
  }
}

.bar:nth-child(1) {
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  animation-duration: 400ms;
}
.bar:nth-child(6) {
  animation-duration: 427ms;
}
.bar:nth-child(7) {
  animation-duration: 441ms;
}
.bar:nth-child(8) {
  animation-duration: 419ms;
}
.bar:nth-child(9) {
  animation-duration: 487ms;
}
.bar:nth-child(10) {
  animation-duration: 442ms;
}

.rgvideo .rvlist li {
  list-style: none;
  font-size: 15px;
  margin-bottom: 10px;
  width: 260px;
  height: 260px;
  display: block;
  cursor: pointer;
  line-height: 60px;
  text-align: center;
  background-color: #ccc;
  border-radius: 15px;
  border: 2px solid #e1bebe;
}

.urname {
  padding-left: 15px;
}

.recrdinum {
  padding-left: 15px;
  font-size: 14px;
  color: #333;
}

.vidrecord {
  background-color: rgb(102 102 102 / 48%);
  border-radius: 15px;
  height: 45px;
  padding: 0px 10px;
  color: #fff;
  line-height: 45px;
  text-align: center;
}

.recrdinum {
  color: #fff;
}

.urpick {
  width: 45px;
  height: 45px;
  text-align: center;
  background-color: #ddd;
  border-radius: 5px;
}

.urpick img {
  border-radius: 5px;
}

.urname h5 {
  font-size: 12px;
  color: #444;
  margin-bottom: 2px;
}

.urname h4 {
  font-size: 15px;
  color: #444;
  margin-top: 0px;
  margin-bottom: 0px;
}

.recrdicon i {
  color: #f84442;
  font-size: 15px;
}

.video_chat {
  background-color: #edf0f5;
  padding: 5px 15px 25px;
  border-radius: 10px;
  height: 90vh;
}

.particbox {
  background-color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.partcont {
  position: relative;
}

.partcont h5 {
  font-size: 14px;
  color: #6c6c6c;
  margin-top: 4px;
  padding-left: 15px;
  font-weight: 400;
}

.pthbox {
  display: none;
  position: absolute;
  top: 40px;
  left: 20px;
}

.partcont:hover .pthbox {
  display: block;
}

.vdmutestn {
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_chat h3 {
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
  color: #333333;
  text-align: left;
}

.nav-link {
  border: none;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  transition: all 0.2s linear;
}

.nav-link.active {
  background-color: #007bff !important;
  color: #ffffff !important;
}

.nav-tabs {
  border: none;
}

.chatbox {
  margin-bottom: 15px;
}

.chatimg {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 10px;
}

.chattext h5 {
  font-size: 10px;
  color: #333;
  margin-bottom: 2px;
}

.you h5 {
  font-size: 10px;
  color: #333;
  margin-bottom: 2px;
}

@media only screen and (max-width: 1000px) {
  * {
    font-size: 12px;
  }

  .btmcall ul li a {
    font-size: 16px;
  }
}

.chattext p {
  font-size: 12px;
  color: rgb(39, 41, 46);
  margin-bottom: 2px;
  font-weight: 600;
}

.you p {
  font-size: 12px;
  /* margin-bottom: 2px; */
}

.chatimg img {
  border-radius: 50%;
}

.tpshare span {
  font-size: 14px;
  color: #333;
}

.tpshare span a {
  text-align: none;
  font-size: 14px;
  text-decoration: none;
  color: #333333;
}

.tpshare span a:hover {
  color: #007bff;
}

.chattext {
  max-width: 85%;
  margin-top: 2px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.you {
  margin-left: auto;
  margin-right: 15px;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 85%;
  flex-direction: column;
  flex-direction: row-reverse;
  margin-top: 2px;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.vdmutestn li {
  padding-right: 15px;
}
.vdmutestn ul {
  margin: 3px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.chtxbox {
  overflow: hidden;
  background-color: #ffffff;
  padding: 10px;
  font-weight: 400;
  max-width: 99%;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  flex: 1 1 auto;
  clear: both;
}

.chtxbox:after {
  position: absolute;
  left: -12px;
  right: 0;
  bottom: -11px;
  content: "";
  height: 0;
  transform: rotate(49deg);
  width: 0;
  border: 11px solid transparent;
  border-top: 14px solid #fff;
}

.chtxbox p,a{
  word-break: break-all;
}

.semdmsg {
  width: 80%;
  bottom: 15px;
  position: absolute;
  margin-bottom: 10px;
}
.semdmsg input,
button {
  font-size: 14px;
  outline: 0;
  height: 40px;
  border: none;
}

.stopScreenShareButton button {
  background: #4087f2;
  padding: 2px 20px;
  border-radius: 5px;
  color: #fff;
}

.semdmsg input:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.mainboxes {
  background-color: #fff;
  padding: 20px;
  height: 100%;
  border-radius: 12px;
}

.youbox {
  background-color: rgb(208, 211, 227);
  padding: 10px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  flex: 1 1 auto;
  clear: both;
}

.you p,a{
  word-break: break-all;
}

.youbox p {
  display: inline-block;
  font-weight: 600;
  margin: 0 !important;
}

.youbox:after {
  position: absolute;
  right: -4px;
  bottom: -11px;
  content: "";
  height: 0;
  transform: rotate(49deg);
  width: 0;
  border: 11px solid transparent;
  border-top: 14px solid rgb(208, 211, 227);
}

.tyicon {
  width: 30px;
  height: 30px;
  line-height: 32px;
  background-color: #fff;
  text-align: center;
  color: #ccc;
  font-size: 12px;
  border-radius: 50px;
}

.typing p {
  font-size: 14px;
  padding-top: 5px;
  margin-left: 10px;
}

.tpshare {
  max-width: 350px;
  margin: auto;
}

.invte input {
  background-color: #ddd;
  height: 40px;
}

.invte input:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
  border-color: #ddd;
}

.tpshare p {
  margin-top: 8px;
  font-size: 14px;
}

.thanksmsg p {
  font-size: 14px;
  margin-top: 30px;
}

.thumbTop {
  position: absolute;
  width: 100%;
  top: 5%;
  padding: 5px 5px;
}
.vduser {
  z-index: 99;
  max-width: 190px;
  height: fit-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f0f0f09c;
  border: 1px solid #ececec;
}
.rgvideo {
  padding-right: 20px;
  z-index: 5;
  top: 12%;
  overflow: scroll;
  right: 4%;
}

.rgvideo .rvlist li {
  list-style: none;
  font-size: 15px;
  margin-bottom: 10px;
  width: 82px;
  height: 94px;
  display: block;
  cursor: pointer;
  line-height: 60px;
  overflow: hidden;
  text-align: center;
  background-color: #ccc;
  border-radius: 15px;
  border: 2px solid #e1bebe;
}
.inital {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  background-color: #4087f2;
}

.initalParticipant {
  margin-top: -7px;
}

.endCallButton {
  transform: rotate(132deg);
}

.centerInParenetInital {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -47%);
  margin: -25px 0 0 -25px;
}

.screenShareCss {
  object-fit: contain !important;
}

.main_partn {
  width: 100%;
  position: relative;
}

.dropdown-menu {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  padding: 5px 5px;
  display: none;
  border: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.modal-title {
  font-size: 15px;
}

.remane {
  padding: 15px 20px;
}

.chatbox.d-flex {
  margin-top: 20px;
}

.innerVideoDiv {
  height: 100%;
  width: 100;
}
.vdcalicon {
  z-index: 9;
  margin-bottom: 10px;
}

.modal.Videopop {
  background-color: rgb(0 0 0 / 47%);
}

.Videopop .modal-dialog {
  max-width: 300px;
  top: 20%;
}

.Vdchat {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.Vdchat:last-child {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.chckbx span {
  display: inline-block;
  padding-left: 10px;
}

#container {
  height: 500px;
}
#container {
  height: 80%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body {
  background-color: #d5d5d5 !important;
}
#imageView {
  width: 100%;
}
#imageTemp {
  position: absolute;
  top: 1px;
  left: 1px;
}
canvas {
  border-radius: 5px;
  /* cursor: url(process.env.PUBLIC_URL), crosshair; */
}
#text_tool {
  position: absolute;
  border: 1px dashed black;
  outline: 0;
  z-index: 1000 !important;
  display: none;
  overflow: hidden;
  white-space: nowrap;
}
.container .btn.btn-sm {
  background: white;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in;
  justify-content: center;
  height: 40px;
  width: 50px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 40px;
  border: none;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
  opacity: 0.3;
}

.container .btn:hover {
  opacity: 0.7;
}

.container .btn.btn-sm:focus {
  opacity: 1;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  border: 3px solid #f5f1ed;
  border-radius: 100%;
  border-top: 3px solid #252323;
  width: 60px;
  height: 60px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* label {
  color: #fff;
} */

.input_box {
  padding: 0 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.file_input {
  display: none;
}

.file_label {
  height: 100% !important;
  margin-top: 10px;
  display: flex;
  margin-right: 10px;
}

.attachIcon {
  font-size: 20px !important;
  cursor: pointer;
  align-self: center;
}

.input_group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 100px;
  background-color: #fff !important;
}

.input_append {
  flex: 1;
  padding: 0 10px;
  background-color: transparent;
}

.input_group button {
  background-color: transparent;
  margin-right: 5px;
}

.send_btn i {
  color: #4087f2;
}
