@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #d5d5d5;
  font-family: "Rubik", sans-serif;
}

img {
  max-width: 100%;
}

.main_wrapper {
  width: 100%;
  height: 150%;
  padding-top: 20px;
  background: #efefef;
}

.leftbar {
  max-width: 300px;
  width: 100%;
  height: 100vh;
  background-color: #ebebeb;
}

.navtab {
  width: 100%;
  padding: 15px;
}

.logo {
  padding: 15px;
  margin-bottom: 20px;
}

.logo h1 {
  font-size: 26px;
  color: #ffffff;
  padding-top: 20px;
}

.rightbar {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.videostn {
  padding: 120px 20px;
}

.setUpVideoPlayer {
  height: 100%;
  width: 100%;

  object-fit: cover;
  border-radius: 6px;
  filter: blur(0px);
  opacity: 1;
}
.mirrorView {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);

  transform: rotateY(180deg);
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}

.completeHide {
  display: none !important;
}
.white,
.white a {
  color: #fff;
}
.videopart {
  width: 100%;

  background-color: #fff;
  border-radius: 5px;
  position: relative;
  /* box-shadow: 0px 0px 8px #dbdbdb;*/
}

.usericon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: absolute;
  top: 35%;
  left: 45%;
  border-radius: 50%;
  background-color: #4dc1ed;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
}

.vdlefticn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  background-color: #666;
  margin-left: 5px;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.vdlefticn:hover {
  background-color: #555;
}

.vdrighticn ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}

.vdrighticn ul li a {
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  background-color: #666;
  color: #fff;
  text-align: center;
  font-size: 15px;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.vdrighticn ul li a:hover {
  background-color: #555;
}

.vdrighticn ul li i {
  font-size: 18px;
}

.vdbotm {
  width: 95%;
  position: absolute;
  bottom: 2px;
}

.loginbox {
  background-color: #fff;
  border-radius: 4px;
}

.loginbox input {
  height: 45px;
  font-weight: 500;
}

#usrName::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  opacity: 0.5;
}
#usrName:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  opacity: 0.5;
}
#usrName::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0.5;
}
#usrName:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.5;
}
#usrName::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.5;
}

#usrName::placeholder {
  /* Most modern browsers support this now. */
  opacity: 0.5;
}

.loginbox button {
  height: 45px;
}

.permision {
  background: rgb(231 203 203);
  padding: 10px 15px;
}

.permision p {
  font-size: 15px;
  margin-bottom: 0px;
}

.persn {
  width: 30%;
}

.persn i {
  float: left;
  width: 40px;
  height: 60px;
  color: #ed6464;
  font-size: 24px;
  line-height: 50px;
}

.prcont {
  width: 70%;
}

.advanced {
  display: block;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.advanced:hover {
  border: 1px solid #2ab0e3;
}

.advicon {
  padding-top: 20px;
  font-size: 18px;
}

.advicon i {
  color: rgb(64, 135, 242);
  font-size: 26px;
}

.adcnts {
  padding-left: 15px;
}

.adcnts h5 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 16px;
}

.advand_show {
  margin-top: 15px;
}

.advand_show input {
  height: auto;
}

.advand_show h5 {
  font-size: 16px;
  margin-bottom: 30px;
}

.vdrighticn img {
  width: 25px;
}

.advand_show a {
  text-decoration: none;
  color: #333;
}

.ckshow input {
  height: 35px;
}

.adbackbt {
  margin-bottom: 30px;
}

.backfrm {
  color: #333;
  font-size: 15px;
  cursor: pointer;
}

.padd1 {
  padding-left: 5px;
  padding-right: 5px;
}

.video {
  width: 100%;
  height: 350px;
  background-color: #fff;
  position: relative;
}

.mainboxes h3 {
  text-align: center;
  font-size: 24px;
  padding-top: 15px;
}

.viseicon {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 50px;
  height: 40px;
  line-height: 40px;
  background-color: #666;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.viseicon:hover {
  background-color: #414141;
}

.mutemike .btn-light,
.volspekr .btn-light {
  background-color: #efefef !important;
  border: none;
}

.vdclist {
  margin: 0px;
  padding: 0px;
}

.vdclist li {
  list-style: none;
  display: inline-block;
  margin-left: 5px;
}

.vdclist li a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  display: block;
  text-align: center;
  transition: all 0.9s ease-in-out;
}

.vdclist li a:hover {
  background-color: #318ff3;
}

.vdclist li a i {
  transition: all 0.9s ease-in-out;
}

.vdclist li a:hover i {
  transform: scale(1.2);
}

.container {
  max-width: 1180px;
  max-height: 70vh;
  height: 90%;
  min-height: 92vh;
}

.mainScreen_videoTimer {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.justify_align_flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(225, 225, 225, 0.5);
}

/* .loading img{
  height: 100%;

  width: auto;
} */

.admin_options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(64, 135, 242);
  color: white;
  font-size: 16px;
  transition: 0.1s all linear;
  height: 0px;
  opacity: 0;
  overflow: hidden;
}

.hideAdminOptions {
  height: 0px;
  opacity: 0;
}

.showAdminOptions {
  height: 100%;
  transform: translateY(0px);
  opacity: 1;
}

.admin_options .adminOption {
  padding: 5px 10px;
  display: flex;
  width: 100%;
  border: 1px solid snow;
  border-bottom: none;
  transition: 0.1s all linear;
}

.admin_options .adminOption:hover {
  padding: 5px 10px;
  display: flex;
  width: 100%;
  color: rgb(64, 135, 242);
  background-color: white;
  border: 1px solid snow;
  border-bottom: none;
}
